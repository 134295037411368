import { ThemeProvider } from '@emotion/react';
import mainTheme from '../css/themes/mainTheme';
import { Autocomplete, CssBaseline, Divider, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setSelectedAdmin, setSelectedLocation } from '../redux/dataslices/settingsSlice';
import { GetLocationsList } from '../helpers/dataHandlers/Locations';
import { getToken } from '../redux/dataslices/tokenSlice';
import { setLocationsAll } from '../redux/dataslices/locationSlice';
import { useSnackbar } from 'notistack';
import { useTranslation } from "react-i18next";
import i18n from "i18next";

export default function SettingsForm(props) {
  const token = useSelector(getToken)
  const hashAdminsAll = props.hashAdminsAll
  const hashLocationsAll = props.hashLocationsAll
  const selectedAdmin = props.selectedAdmin
  const selectedLocation = props.selectedLocation
  const [selectedLanguage, setSelectedLanguage] = useState(JSON.parse(localStorage.getItem("locale")) || { label: "English", id: "en" });
  const [adminSelectFieldOptions, setAdminSelectFieldOptions] = useState([])
  const [locationSelectFieldOptions, setLocationSelectFieldOptions] = useState([])
  const languageSelectFieldOptions = [{ label: "English", id: "en" }, { label: "Nederlands", id: "nl" }]
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const onSelectAdmin = (newValue) => {
    dispatch(setSelectedAdmin(newValue))
    dispatch(setSelectedLocation(null))
    localStorage.setItem("selected_admin", JSON.stringify(newValue))
    localStorage.removeItem("selected_location")
    if (newValue) {
      GetLocationsList(token, newValue?.id)
        .then(response => {
          dispatch(setLocationsAll(response.data))
        })
        .catch(error => {
          enqueueSnackbar({ message: `${t("(Locations)")} ${error?.message}`, variant: 'error' });
        })
    }
    enqueueSnackbar({ message: t(`New administration selected`), variant: 'info' });
  }

  const onSelectLocation = (newValue) => {
    dispatch(setSelectedLocation(newValue))
    localStorage.setItem("selected_location", JSON.stringify(newValue))
    enqueueSnackbar({ message: t(`New location selected`), variant: 'info' });
  }

  const onSelectLanguage = (newValue) => {
    localStorage.removeItem("localeOverride")
    localStorage.removeItem("locale")
    setSelectedLanguage(newValue);
    if (newValue) {
      localStorage.setItem("localeOverride", JSON.stringify(true))
      localStorage.setItem("locale", JSON.stringify(newValue))
    }
    i18n.changeLanguage(newValue?.id || "en")
    enqueueSnackbar({ message: t(`New language selected`), variant: 'info' });
  }

  useEffect(() => {
    let adminList = []

    const labelCount = {}

    for (const admin of Object.values(hashAdminsAll)) {
      let label = admin.name

      // Check if the label already exists, this is NEEDED to have the search function properly
      if (labelCount[label]) {
        labelCount[label]++
        label = `${label} (${labelCount[label]})`; // Append the count to the label
      } else {
        labelCount[label] = 1
      }

      adminList.push({ label: label, id: admin.id })
    }

    setAdminSelectFieldOptions(adminList)
  }, [hashAdminsAll])

  useEffect(() => {
    if (hashLocationsAll) {
      let locationList = []
      const labelCount = {}

      for (const location of Object.values(hashLocationsAll)) {
        let label = location.name

        if (labelCount[label]) {
          labelCount[label]++
          label = `${label} (${labelCount[label]})`
        } else {
          labelCount[label] = 1
        }

        locationList.push({ label: label, id: location.id })
      }

      setLocationSelectFieldOptions(locationList)
    }
  }, [hashLocationsAll])

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <>
        <Divider sx={{ marginTop: 1.5 }}>{t("Administration/Location")}</Divider>
        <Autocomplete
          disablePortal
          value={selectedAdmin}
          id="admin-combo-box"
          options={adminSelectFieldOptions}
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 2
          }}
          onChange={(event, newValue) => {
            onSelectAdmin(newValue)
          }}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => <TextField {...params} label={t("Administration")} />}
        />
        <Autocomplete
          disablePortal
          value={selectedLocation}
          id="location-combo-box"
          options={locationSelectFieldOptions}
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 2
          }}
          onChange={(event, newValue) => {
            onSelectLocation(newValue)
          }}
          disabled={Object.keys(hashLocationsAll).length === 0}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => <TextField {...params} label={t("Location")} />}
        />
        <Divider sx={{ marginTop: 1.5 }}>{t("Language")}</Divider>
        <Autocomplete
          value={selectedLanguage}
          id="location-combo-box"
          options={languageSelectFieldOptions}
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 2
          }}
          onChange={(event, newValue) => {
            onSelectLanguage(newValue)
          }}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => <TextField {...params} label={t("Language")} />}
        />
      </>
    </ThemeProvider>
  );
}