import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selected_admin: JSON.parse(localStorage.getItem("selected_admin")),
  selected_location: JSON.parse(localStorage.getItem("selected_location"))
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSelectedAdmin: (state, action) => {
      state.selected_admin = action.payload;
    },
    setSelectedLocation: (state, action) => {
      state.selected_location = action.payload;
    },
    clearSettings: (state) => {
      state.selected_admin = undefined
      state.selected_location = undefined
      localStorage.removeItem("selected_admin")
      localStorage.removeItem("selected_location")
    }
  },
});

export const { setSelectedAdmin, setSelectedLocation, clearSettings } = settingsSlice.actions;

export const getSelectedAdmin = (state) => state.settings.selected_admin
export const getSelectedLocation = (state) => state.settings.selected_location

export default settingsSlice.reducer;