import { createTheme } from "@mui/material";

const mainTheme = createTheme({
  breakpoints: {
    values: {
      xs: 375, // Iphone SE, 4/5/6/7/8
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#00072d',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FF6700',
      contrastText: '#fff',
    },
    text: {
      primary: '#000000'
    }
  },
  typography: {
    fontFamily: [
      'Montserrat',
    ].join(','),
  },
});

export default mainTheme