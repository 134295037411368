import { ThemeProvider } from '@emotion/react';
import mainTheme from '../css/themes/mainTheme';
import { Box, CssBaseline, Paper, Typography } from '@mui/material';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useSelector } from 'react-redux';
import { getDailystatusData } from '../redux/dataslices/reportingSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ReportingChart() {
  const reportingData = useSelector(getDailystatusData)
  const [graphData, setGraphData] = useState([])
  const { t } = useTranslation();

  useEffect(() => {
    if (!reportingData) return
    let data = []
    for (const [key, object] of Object.entries(reportingData?.hourly)) {
      data.push({ name: key, cash: object?.cash, eft: object?.eft, exchange: object?.exchange, off_account: object?.off_account, on_account: object?.on_account, Total: object?.total })
    }
    setGraphData(data)
  }, [reportingData])


  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      {graphData.length > 0 ?
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ boxShadow: 'none', width: '100%', height: "35vh" }}>
            <ResponsiveContainer>
              <AreaChart
                data={graphData}
                margin={{
                  top: 20,
                  right: 10,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="Total" stroke="#1C77C3" fill="#1C77C3" />
              </AreaChart>
            </ResponsiveContainer>
          </Paper>
        </Box>
        :
        <Typography fontWeight={400}
          sx={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'center', marginTop: 2 }}>
          {t("No data")}
        </Typography>
      }
    </ThemeProvider>
  );
}