import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setDrawerOpen: (state, action) => {
      state.open = action.payload
    }
  },
});

export const { setDrawerOpen } = drawerSlice.actions;

export const getDrawerOpen = (state) => state.drawer.open;

export default drawerSlice.reducer;