// import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import NavbarDrawer from '../components/drawer';
import Navbar from '../components/navbar';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../redux/dataslices/tokenSlice';
import { useEffect } from 'react';
import { TokenExpiredCheck } from '../helpers/dataHandlers/KeycloakAuth';
// import { getToken } from '../redux/dataslices/token';


function App() {
  const token = useSelector(getToken);
  const dispatch = useDispatch();

  useEffect(() => {
    const intervalId = setInterval(async () => {  // Assign interval to a variable to clear it.
      await TokenExpiredCheck({ token: token, dispatch: dispatch });
    }, 10000)

    return () => clearInterval(intervalId); // This is important
  }, [dispatch, token])

  if (!token) {
    return (
      <div>
        <Navigate to='/' />
      </div>
    )
  }

  return (
    <>
      <Navbar />
      <Outlet />
      <NavbarDrawer />
    </>
  );
}

export default App;
