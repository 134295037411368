import { ThemeProvider } from '@emotion/react';
import mainTheme from '../css/themes/mainTheme';
import { Box, CssBaseline, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function DataTable(props) {
  const { t } = useTranslation();

  let euro = Intl.NumberFormat('en-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Box>
        <Table>
          {props.titleEnabled &&
            <TableHead>
              <TableRow>
                <TableCell align="left" colSpan={4}>
                  <Typography variant="h6" color={'#FF6700'} fontWeight={400}>
                    {t(props.title)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          }
          <TableBody>
            {(!props?.data || props?.data?.length === 0) &&
              <TableRow>
                <TableCell>
                  <Typography fontWeight={400}
                    sx={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'center', marginTop: 2 }}>
                    {t("No data")}
                  </Typography>
                </TableCell>
              </TableRow>
            }
            {props?.data?.map((item, index) => (
              <TableRow key={index}>
                {item.title.toLowerCase() === 'total' ? (
                  <TableCell colSpan={3}><b>{t(item.title)}</b></TableCell>
                ) : (
                  <TableCell colSpan={3}>{t(item.title)}</TableCell>
                )}
                {item.format === 'currency' ? (
                  <TableCell align="right">
                    {item.title.toLowerCase() === 'total' ? (
                      <b>{euro.format(item.value)}</b>
                    ) : (
                      euro.format(item.value)
                    )}
                  </TableCell>
                ) : (
                  <TableCell align="right">{item.value}</TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </ThemeProvider>
  );
}