import { useSelector } from "react-redux";
import Card from "../components/card";
import SettingsForm from "../components/settingsForm";
import { getSelectedAdmin, getSelectedLocation } from "../redux/dataslices/settingsSlice";
import { getHashAdministrationsAll } from "../redux/dataslices/administrationSlice";
import { getHashAdminLocationsAll } from "../redux/dataslices/locationSlice";
import { Grid } from "@mui/material";

function Settings() {
  const selectedAdmin = useSelector(getSelectedAdmin)
  const selectedLocation = useSelector(getSelectedLocation)
  const hashAdminsAll = useSelector(getHashAdministrationsAll)
  const hashLocationsAll = useSelector(getHashAdminLocationsAll)

  const settingsCardProps = {
    titleVariant: 'h6',
    titleColor: '#FF6700',
    titleWeight: 600,
    titleText: 'Settings',
    loading: false,
    loadingText: "Loading settings...",
    data: <SettingsForm
      hashAdminsAll={hashAdminsAll}
      hashLocationsAll={hashLocationsAll}
      selectedAdmin={selectedAdmin}
      selectedLocation={selectedLocation} />
  }

  return (
    <>
      <Grid container sx={{ paddingTop: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} width={'100%'}>
          <Card {...settingsCardProps} />
        </Grid>
      </Grid>
    </>
  );
}

export default Settings;
