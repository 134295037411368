import { ThemeProvider } from '@emotion/react';
import mainTheme from '../css/themes/mainTheme';
import { Box, CircularProgress, CssBaseline, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function Card(props) {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Box sx={{
        borderRadius: '9px',
        borderColor: 'primary',
        backgroundColor: 'white',
        padding: 2,
        margin: 1,
        minHeight: 150,
        overflowY: 'visible',
        overflowX: 'hidden',
        overflowWrap: 'break-word',
        ...props.sx
      }}>
        <>
          <Typography variant={props.titleVariant ?? 'h6'} color={props.titleColor ?? '#000000'} fontWeight={props.titleWeight ?? 600}>
            {t(props.titleText) ?? t('Title')}
          </Typography>
          {props.loading ?
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100%',
                padding: '20px',
              }}
            >
              <Box sx={{ flexGrow: 1 }} /> {/* Flex grow to push content to center vertically */}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CircularProgress color='inherit' />
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                  {t(props.loadingText) ?? t("Loading...")}
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }} /> {/* Flex grow to push content to center vertically */}
            </Box>
            :
            <>
              {props.data}
            </>
          }
        </>
      </Box>
    </ThemeProvider>
  );
}