import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { languageHash } from "./helpers/languageHash";

if (!JSON.parse(localStorage.getItem("localeOverride"))) {
  localStorage.setItem("locale", JSON.stringify(languageHash["en"]))
  if (languageHash[navigator.language.split('-')[0]]) localStorage.setItem("locale", JSON.stringify(languageHash[navigator.language.split('-')[0]]))
}

const locale = JSON.parse(localStorage.getItem("locale"))?.id || "en"

i18n
  .use(initReactI18next)
  .init({
    lng: locale,
    fallbackLng: 'en',
    debug: process.env.REACT_APP_TRANSLATE_DEBUG === 'true',
    resources: {
      en: {
        translation: require(`./translations/en.json`)
      },
      nl: {
        translation: require(`./translations/nl.json`)
      }
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    },
  });

export default i18n;