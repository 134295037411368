import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const reportingSlice = createSlice({
  name: 'reporting',
  initialState,
  reducers: {
    setDailystatusData: (state, action) => {
      state.dailystatus_data = action.payload;
    },
    clearReporting: (state) => {
      state.dailystatus_data = undefined
    }
  },
});

export const { setDailystatusData, clearReporting } = reportingSlice.actions;

export const getDailystatusData = (state) => state.reporting.dailystatus_data

export default reportingSlice.reducer;