import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading_queue: {}
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    /**
     * Function to set whole loading queue
     * @param {object} action The full loading queue with hashes, can also be used with null to clear the queue.
     */
    setLoadingQueue: (state, action) => {
      if (action.payload == null) {
        state.loading_queue = {}
        return
      }
      state.loading_queue = action.payload
    },
    /**
     * Function to set a specific loading hash
     * @param {Array} action An array with index 0 being the hash name and index 1 being the loading state.
     * 
     * If hash isn't found, it adds a new one.
     * 
     * If payload length is less than or greater than 2, function does nothing.
     */
    setLoading: (state, action) => {
      if (action.payload.length !== 2) return;
      const hash = action.payload[0];
      const loading = action.payload[1];

      state.loading_queue[hash] = loading;
    },
  },
});

export const { setLoadingQueue, setLoading } = loadingSlice.actions;

/**
 * Function to get full loading queue
 */
export const getLoadingQueue = (state) => state.loading.loading_queue;
/**
 * Function to get a specific loading hash
 * @param {string} hash The hash to get the loading state from
 */
export const getLoading = (state, hash) => state.loading.loading_queue[hash];

export default loadingSlice.reducer;