import { ThemeProvider } from '@emotion/react';
import mainTheme from '../css/themes/mainTheme';
import { Autocomplete, Box, CircularProgress, CssBaseline, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { setSelectedAdmin, setSelectedLocation } from '../redux/dataslices/settingsSlice';
import { useTranslation } from 'react-i18next';

export default function SelectAdminLocationDashboard(props) {
  const hashAdminsAll = props.hashAdminsAll
  const hashLocationsAll = props.hashLocationsAll
  const selectedAdmin = props.selectedAdmin
  const selectedLocation = props.selectedLocation
  const [adminSelectFieldOptions, setAdminSelectFieldOptions] = useState([])
  const [locationSelectFieldOptions, setLocationSelectFieldOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation();
  const [loadingText, setLoadingText] = useState("Loading...")
  const dispatch = useDispatch()

  const onSelectAdmin = (newValue) => {
    dispatch(setSelectedAdmin(newValue))
    localStorage.setItem("selected_admin", JSON.stringify(newValue))
  }

  const onSelectLocation = (newValue) => {
    dispatch(setSelectedLocation(newValue))
    localStorage.setItem("selected_location", JSON.stringify(newValue))
  }

  useEffect(() => {
    let adminList = []
    setLoading(true)
    setLoadingText("Loading administrations...")

    const labelCount = {}

    for (const admin of Object.values(hashAdminsAll)) {
      let label = admin.name

      // Check if the label already exists, this is NEEDED to have the search function properly
      if (labelCount[label]) {
        labelCount[label]++
        label = `${label} (${labelCount[label]})`; // Append the count to the label
      } else {
        labelCount[label] = 1
      }

      adminList.push({ label: label, id: admin.id })
    }

    setAdminSelectFieldOptions(adminList)
    setLoading(false)
  }, [hashAdminsAll])

  useEffect(() => {
    if (adminSelectFieldOptions.length > 0) {
      setLoading(false)
    }
  }, [adminSelectFieldOptions])

  useEffect(() => {
    if (hashLocationsAll) {
      let locationList = []
      const labelCount = {}
  
      for (const location of Object.values(hashLocationsAll)) {
        let label = location.name
  
        if (labelCount[label]) {
          labelCount[label]++
          label = `${label} (${labelCount[label]})`
        } else {
          labelCount[label] = 1
        }
  
        locationList.push({ label: label, id: location.id })
      }
  
      setLocationSelectFieldOptions(locationList)
    }
  }, [hashLocationsAll])

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      {loading ?
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: 'fit-content',
          }}>
          <CircularProgress color='inherit' />
          <Typography variant="body1" sx={{ marginLeft: 1 }}>
            {t(loadingText)}
          </Typography>
        </Box>
        :
        <>
          <Autocomplete
            disablePortal
            value={selectedAdmin}
            id="admin-combo-box"
            options={adminSelectFieldOptions}
            sx={{
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: 2
            }}
            onChange={(event, newValue) => {
              onSelectAdmin(newValue)
            }}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} label={t("Administration")} />}
          />
          <Autocomplete
            value={selectedLocation}
            id="location-combo-box"
            options={locationSelectFieldOptions}
            sx={{
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: 2
            }}
            onChange={(event, newValue) => {
              onSelectLocation(newValue)
            }}
            disabled={Object.keys(hashLocationsAll).length === 0}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} label={t("Location")} />}
          />
        </>
      }
    </ThemeProvider>
  );
}