import Box from '@mui/material/Box';
import { ThemeProvider } from '@emotion/react';
import mainTheme from '../css/themes/mainTheme';
import { Button, CssBaseline, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getDrawerOpen, setDrawerOpen } from '../redux/dataslices/drawerSlice';
import CopyrightText from './copyrightText';
import VersionText from './versionText';
import ActablueLogoNoText from '../static/icons/logo_png_transparant_icononly.png'
import { House, Settings } from '@mui/icons-material';
import { clearToken } from '../redux/dataslices/tokenSlice';
import { useNavigate } from 'react-router-dom';
import { clearReporting } from '../redux/dataslices/reportingSlice';
import { clearLocations } from '../redux/dataslices/locationSlice';
import { clearAdministrations } from '../redux/dataslices/administrationSlice';
import { useTranslation } from 'react-i18next';

export default function NavbarDrawer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const drawerOpen = useSelector(getDrawerOpen);
  const { t } = useTranslation();

  const onClickLogout = () => {
    dispatch(clearToken())
    dispatch(clearReporting())
    dispatch(clearLocations())
    dispatch(clearAdministrations())
    // dispatch(clearSettings())
    navigate("/")
  }

  const onClickDashboard = () => {
    navigate("/dashboard")
  }

  const onClickSettings = () => {
    navigate("/settings")
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    dispatch(setDrawerOpen(open))
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {/* Header */}
      <Box
        marginBottom={2}
        sx={{
          width: '100%',
          maxWidth: 82,
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          mt: 2
        }}
      >
        <img
          src={ActablueLogoNoText}
          alt='ActaBlue Logo'
          style={{ width: '100%', height: 'auto' }}
        />
      </Box>
      <Divider />
      {/* Content */}
      <Box sx={{ flex: 1, overflow: 'auto', marginTop: 2, marginLeft: 0 }}>
        <List>
          <ListItem key={'Dashboard'} onClick={() => onClickDashboard()} onKeyDown={() => onClickDashboard()} disablePadding>
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: 35 }}>
                <House />
              </ListItemIcon>
              <ListItemText primary={t('Dashboard')} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'Settings'} onClick={() => onClickSettings()} onKeyDown={() => onClickSettings()} disablePadding>
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: 35 }}>
                <Settings />
              </ListItemIcon>
              <ListItemText primary={t('Settings')} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Box margin={2}>
        <Button
          fullWidth
          onClick={onClickLogout}
          variant="contained"
          color='secondary'
          sx={{ borderRadius: '9px', minHeight: '2.5rem' }}>
          {t("Logout")}
        </Button>
      </Box>
      <Divider />
      {/* Footer */}
      <Typography variant="caption" sx={{ p: 2 }}>
        <CopyrightText />
        <VersionText />
      </Typography>
    </Box>
  );

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <SwipeableDrawer
        anchor={'right'}
        open={drawerOpen}
        onClose={toggleDrawer('right', false)}
        onOpen={toggleDrawer('right', true)}
        hysteresis={0.52}
        transitionDuration={350}
        PaperProps={{
          sx: {
            '@supports (-webkit-touch-callout: none)': {
              marginTop: 'max(1.25rem, env(safe-area-inset-top))',
            }
          },
        }}
      >
        {list('right')}
      </SwipeableDrawer>
    </ThemeProvider>
  );
}