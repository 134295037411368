import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const packageJson = require("../../package.json")
const currentVersion = packageJson.version

export default function VersionText(props) {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {t('Version ')}
      {currentVersion}
    </Typography>
  );
}