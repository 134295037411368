import { configureStore } from '@reduxjs/toolkit'
import tokenReducer from "./dataslices/tokenSlice"
import drawerReducer from "./dataslices/drawerSlice"
import adminReducer from "./dataslices/administrationSlice"
import locationReducer from "./dataslices/locationSlice"
import settingsReducer from "./dataslices/settingsSlice"
import reportingReducer from "./dataslices/reportingSlice"
import loadingReducer from "./dataslices/loadingSlice";

export const store = configureStore({
  reducer: {
    token: tokenReducer,
    drawer: drawerReducer,
    administration: adminReducer,
    location: locationReducer,
    settings: settingsReducer,
    reporting: reportingReducer,
    loading: loadingReducer
  },
})