import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hash_administrations_all: {}
};

export const administrationSlice = createSlice({
  name: 'administrations',
  initialState,
  reducers: {
    setAdministrationId: (state, action) => {
      state.admin_id = action.payload;
    },
    setAdministration: (state, action) => {
      state.administration = action.payload;
    },
    setAdministrations: (state, action) => {
      state.administrations = action.payload
    },
    setAdministrationsAll: (state, action) => {
      state.administrations_all = action.payload
      action.payload.forEach(admin => {
        state.hash_administrations_all[admin.id] = admin
      });
    },
    clearAdministrations: (state) => {
      state.admin_id = undefined
      state.administration = undefined
      state.administrations = undefined
      state.administrations_all = undefined
      state.hash_administrations_all = {}
    }
  },
});

export const { setAdministration, setAdministrationId, setAdministrations, setAdministrationsAll, clearAdministrations } = administrationSlice.actions;

export const getAdministrationId = (state) => state.administration.admin_id
export const getAdministration = (state) => state.administration.administration
export const getAdministrations = (state) => state.administration.administrations
export const getAdministrationsAll = (state) => state.administration.administrations_all
export const getHashAdministrationsAll = (state) => state.administration.hash_administrations_all

export default administrationSlice.reducer;