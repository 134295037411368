import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hash_locations_all: {},
  hash_admin_locations_all: {},
};

export const locationSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLocationId: (state, action) => {
      state.location_id = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setLocations: (state, action) => {
      state.locations = action.payload
    },
    setLocationsAllAdmins: (state, action) => {
      state.locations_all_admins = action.payload
      state.hash_locations_all = {}
      action.payload.forEach(location => {
        state.hash_locations_all[location.id] = location
      });
    },
    setLocationsAll: (state, action) => {
      state.locations_all = action.payload
      state.hash_admin_locations_all = {}
      action.payload.forEach(location => {
        state.hash_admin_locations_all[location.id] = location
      });
    },
    clearLocations: (state) => {
      state.location_id = undefined
      state.location = undefined
      state.locations = undefined
      state.locations_all_admins = undefined
      state.hash_locations_all = {}
      state.hash_admin_locations_all = {}
    }
  },
});

export const { setLocation, setLocationId, setLocations, setLocationsAll, setLocationsAllAdmins, clearLocations } = locationSlice.actions;

export const getLocationId = (state) => state.location.location_id
export const getLocation = (state) => state.location.location
export const getLocations = (state) => state.location.locations
export const getLocationsAll = (state) => state.location.locations_all
export const getLocationsAllAdmins = (state) => state.location.locations_all_admins
export const getHashLocationsAll = (state) => state.location.hash_locations_all
export const getHashAdminLocationsAll = (state) => state.location.hash_admin_locations_all

export default locationSlice.reducer;