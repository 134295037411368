import { createBrowserRouter } from "react-router-dom";
import Login from "../main/login";
import App from "../main/app";
import Dashboard from "../main/dashboard";
import Settings from "../main/settings";

export default function router() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Login />
        },
        {
            element: <App />,
            children: [
                {path: "dashboard", element: <Dashboard />},
                {path: "settings", element: <Settings />}
            ]
        }
    ])

    return router
}