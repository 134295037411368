import axios from "axios";

export function GetSerialsList(token) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/serials/serial/list"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
}

export function GetUserById(token, id) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/serials/user/${id}`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};