import moment from "moment";
import Card from "../components/card";
import DataTable from "../components/dataTable";
import ReportingChart from "../components/reportingChart";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRole, getToken } from "../redux/dataslices/tokenSlice";
import { GetSerialsList } from "../helpers/dataHandlers/Serials";
import { getHashAdministrationsAll, setAdministrationsAll } from "../redux/dataslices/administrationSlice";
import SelectAdminLocationDashboard from "../components/selectAdminLocationDashboard";
import { getSelectedAdmin, getSelectedLocation } from "../redux/dataslices/settingsSlice";
import { GetLocationsList } from "../helpers/dataHandlers/Locations";
import { getHashAdminLocationsAll, setLocationsAll } from "../redux/dataslices/locationSlice";
import { GetStatusReportDay } from "../helpers/dataHandlers/Reporting";
import { getDailystatusData, setDailystatusData } from "../redux/dataslices/reportingSlice";
import { useSnackbar } from "notistack";
import PullToRefresh from "react-simple-pull-to-refresh";
import { CssBaseline, Grid, ThemeProvider } from "@mui/material";
import mainTheme from "../css/themes/mainTheme";
import { getLoadingQueue, setLoading } from "../redux/dataslices/loadingSlice";

function Dashboard() {
  const token = useSelector(getToken)
  const role = useSelector(getRole)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const selectedAdmin = useSelector(getSelectedAdmin)
  const selectedLocation = useSelector(getSelectedLocation)
  const hashAdminsAll = useSelector(getHashAdministrationsAll)
  const hashLocationsAll = useSelector(getHashAdminLocationsAll)
  const reportingData = useSelector(getDailystatusData)
  const [paymentsTableData, setPaymentsTableData] = useState()
  const [highlightsTableData, setHighlightsTableData] = useState()
  const loadingQueue = useSelector(getLoadingQueue);

  const paymentsCardProps = {
    titleVariant: 'h6',
    titleColor: '#FF6700',
    titleWeight: 600,
    titleText: 'Payments',
    loading: loadingQueue['reporting'],
    loadingText: "Loading payments...",
    data: <DataTable data={paymentsTableData} />,
    sx: {
      height: { xs: 'fit-content', sm: 'fit-content', md: 300 }
    }
  }

  const highlightsCardProps = {
    titleVariant: 'h6',
    titleColor: '#FF6700',
    titleWeight: 600,
    titleText: 'Highlights',
    loading: loadingQueue['reporting'],
    loadingText: "Loading highlights...",
    data: <DataTable data={highlightsTableData} />,
    sx: {
      height: { xs: 'fit-content', sm: 'fit-content', md: 300 }
    }
  }

  const reportingChartCardProps = {
    titleVariant: 'h6',
    titleColor: '#FF6700',
    titleWeight: 600,
    titleText: 'Revenue per hour',
    loading: loadingQueue['reporting'],
    loadingText: "Loading reporting data...",
    data: <ReportingChart />
  }

  const selectAdminLocationCardProps = {
    titleVariant: 'h7',
    titleColor: '#FF6700',
    titleWeight: 600,
    titleText: 'Select admin and location',
    data: <SelectAdminLocationDashboard
      hashAdminsAll={hashAdminsAll}
      hashLocationsAll={hashLocationsAll}
      selectedAdmin={selectedAdmin}
      selectedLocation={selectedLocation} />,
  }

  useEffect(() => {
    if (role === 'user') {
      // TODO: User role implementation
    }
    if (role !== 'user') {
      dispatch(setLoading(['serials', true]))
      GetSerialsList(token)
        .then(response => { dispatch(setAdministrationsAll(response.data)) })
        .catch(error => {
          enqueueSnackbar({ message: `(Serials) ${error?.message}`, variant: 'error' });
        })
        .finally(() => {
          dispatch(setLoading(['serials', false]))
        });
    }
    // eslint-disable-next-line
  }, [role, token])

  useEffect(() => {
    if (selectedAdmin) {
      if (role !== 'user') {
        dispatch(setLoading(['locations', true]))
        GetLocationsList(token, selectedAdmin?.id)
          .then(response => {
            dispatch(setLocationsAll(response.data))
          })
          .catch(error => {
            enqueueSnackbar({ message: `(Locations) ${error?.message}`, variant: 'error' });
          })
          .finally(() => {
            dispatch(setLoading(['locations', false]))
          });
      }
    }
    // eslint-disable-next-line
  }, [role, selectedAdmin, token])

  useEffect(() => {
    dispatch(setLoading(['reporting', true]))
    const fetchData = async () => {
      if (selectedAdmin && selectedLocation) {
        GetStatusReportDay(token, selectedAdmin?.id, selectedLocation?.id, moment().unix(), hashLocationsAll[selectedLocation?.id])
          .then(response => {
            dispatch(setDailystatusData(response.data))
          })
          .catch(error => {
            enqueueSnackbar({ message: `(Reporting) ${error?.message}`, variant: 'error' });
          })
          .finally(() => {
            dispatch(setLoading(['reporting', false]))
          });
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [token, selectedAdmin, selectedLocation])

  useEffect(() => {
    dispatch(setLoading(['reportingData', true]))
    if (!reportingData) return

    const processReportingData = new Promise((resolve, reject) => {
      try {
        const notIncludedKeys = ['exchange', 'off_account']
        let paymentsData = []
        let highlightsData = []

        for (const [key, value] of Object.entries(reportingData?.totals)) {
          if (!notIncludedKeys.includes(key)) paymentsData.push({ title: key.toUpperCase(), value: value, format: 'currency' })
        }
        setPaymentsTableData(paymentsData)

        if (reportingData?.transactions !== 0) {
          highlightsData.push({ title: "Transactions", value: reportingData?.transactions, format: 'number' })
          highlightsData.push({ title: "Average", value: (reportingData?.totals?.total / reportingData?.transactions), format: 'currency' })
        }
        setHighlightsTableData(highlightsData)

        resolve(true)
      } catch (error) {
        reject(error)
      }
    })

    processReportingData
      .then(() => {
        dispatch(setLoading(['reportingData', false]))
      })
      .catch(() => {
        enqueueSnackbar({ message: `(Reporting) Error while processing reporting data.`, variant: 'error' });
      })


    // eslint-disable-next-line
  }, [reportingData])

  const refresh = () => {
    return new Promise(resolve => {
      const fetchData = async () => {
        if (selectedAdmin && selectedLocation) {
          await GetStatusReportDay(token, selectedAdmin?.id, selectedLocation?.id, moment().unix(), hashLocationsAll[selectedLocation?.id])
            .then(response => {
              resolve(dispatch(setDailystatusData(response.data)))
            })
            .catch(error => {
              resolve(enqueueSnackbar({ message: `(Reporting) ${error?.message}`, variant: 'error' }));
            });
        }
      };
      fetchData();
    });
  };

  return (
    (selectedAdmin && selectedLocation) ?
      <>
        <ThemeProvider theme={mainTheme}>
          <CssBaseline />
          <PullToRefresh isPullable={!loadingQueue['reporting']} onRefresh={refresh} pullingContent={""}>
            <Grid container sx={{ paddingTop: 1 }}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} width={'100%'}>
                <Card {...paymentsCardProps} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} width={'100%'}>
                <Card {...highlightsCardProps} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} width={'100%'}>
                <Card {...reportingChartCardProps} />
              </Grid>
            </Grid>
          </PullToRefresh>
        </ThemeProvider>
      </>
      :
      <>
        <Grid container sx={{ paddingTop: 1 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} width={'100%'}>
            <Card {...selectAdminLocationCardProps} />
          </Grid>
        </Grid>
      </>
  );
}

export default Dashboard;
